<template>
  <div ref="homeContainer" class="home">
    <img class="hospital-banner" :src="banner" alt="" />
    <hospitalInfo :hospitalInfo="hospitalInfo"></hospitalInfo>
    <tips v-if="hospitalInfo.tips" :tips="hospitalInfo.tips"></tips>
    <div v-if="showFilter" class="list-filter-wrap" ref="listFilter">
      <div class="list-filter" :class="isFixed ? 'list-filter-fixed' : ''">
        <filter-buttons :categoryList="categoryList" @change="filterChange" @onOpenFilter="onOpenFilter"/>
      </div>
    </div>

    <div
      class="card-list"
      :style="{ paddingTop: showFilter ? '0': '2.666667vw' }"
      ref="cardList"
    >
      <card-item
        v-for="(item, index) in cardList"
        :key="index"
        :data="item"
        @click.native="goToDetail(item.productHospitalId)"
      />
    </div>
  </div>
</template>

<script>
import hospitalInfo from "@/views/components/home/hospitalInfo.vue";
import tips from "@/views/components/home/tips.vue";
import cardItem from "./components/cardItem";
import filterButtons from "./components/filterButtons.vue";
import {
  GetProductPersonCategoryKeys,
  GetProducts,
  GetHospital,
  PostStatistics,
} from "@/api";
import WxUtil from "@/utils/wxUtils.js";
import { isWechat } from "@/utils/index.js";

import hospitalBanner from "@/assets/hospital-banner.png";
export default {
  name: "home",
  components: {
    hospitalInfo,
    cardItem,
    filterButtons,
    tips
  },
  computed: {
    hospitalId() {
      return this.$store.state.hospitalId;
    },
    openId() {
      return this.$store.state.openid;
    },
    banner() {
      if (this.hospitalInfo?.wxGzhH5MainImageUrlsValue) {
        return this.hospitalInfo.wxGzhH5MainImageUrlsValue;
      } else {
        return this.hospitalBanner;
      }
    },
    showFilter(){
      return this.hospitalId !== 'HS2023011804' //广西科技大学第二附属医院(柳州肿瘤医院)体检中心不显示筛选框
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASEURL,
      hospitalBanner,
      categoryList: [],
      cardList: [],
      hospitalInfo: {},
      code: "",
      isLoading: false,
      isFixed: false,
      clientHeight: document.documentElement.clientHeight,
      scrollTop: 0,
      filterParams: {},
      latitude: "",
      longitude: "",
    };
  },
  watch: {
    openId: {
      handler(newValue) {
        if (newValue) {
          this.sendStatistics();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.$store.commit("setTitle", "医院套餐列表");
    window.addEventListener("scroll", this.onScroll, true);
    if (localStorage.getItem("hospitalId") != this.$route.query.hospitalId) {
      localStorage.setItem("token", "");
      localStorage.setItem("telNo", "");
      localStorage.removeItem("hospitalInfo");
      this.$store.state.showPrivacy = true
      localStorage.removeItem("showPrivacy");
    }
    this.$store.commit(
      "setHospitalId",
      this.$route.query.hospitalId || localStorage.getItem("hospitalId")
    );
    this.getSellingPointKeys();
    this.getProducts();
    this.getHospital();
  },
  methods: {
    async sendStatistics() {
      await PostStatistics({
        openId: this.$store.state.openid,
        hospitalId: this.hospitalId,
        trackingType: 1,
      });
    },

    onScroll() {
      if (this.$refs.listFilter) {
        this.scrollTop = this.$refs.listFilter.getBoundingClientRect().top;
        if (this.scrollTop < 0) {
          this.isFixed = true;
        } else {
          this.isFixed = false;
        }
      }
    },
    filterChange(params) {
      console.log(params);
      this.filterParams = params;
      this.cardList = [];
      this.getProducts();

    },
    onOpenFilter(){
      if(this.isFixed) return
      this.$nextTick(() => {
        this.$refs.homeContainer.scrollTo({
          top: this.$refs.listFilter.offsetTop + 1,
          behavior: "smooth",
        });
      });
    },
    goToDetail(id) {
      this.$router.push("/good-detail/" + id);
    },
    async getSellingPointKeys() {
      let data = await GetProductPersonCategoryKeys({
        HospitalCode: this.hospitalId,
      });
      this.category = data[0].code;
      if (data && data.length) {
        this.categoryList = data.map((item) => {
          return {
            text: item.value,
            value: item.code,
          };
        });
      }
    },
    async getProducts() {
      const params = {
        HospitalId: this.hospitalId,
        HospitalCode: this.hospitalId,
        ...this.filterParams,
      };
      let data = await GetProducts(params);
      if (data.totalPage <= this.pageNo) {
        this.finished = true;
      }

      this.cardList = data.rows;
    },
    async getHospital() {
      let data = await GetHospital({
        HospitalId: this.hospitalId,
        HospitalCode: this.hospitalId,
        latitude: this.latitude,
        longitude: this.longitude,
      });
      this.hospitalInfo = data;
      localStorage.setItem("hospitalInfo", JSON.stringify(data));
      localStorage.setItem("telNo", data.telNo);
      this.$store.commit('setHospitalInfo',data)
      this.$setTitle(data.displayName)
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.hospital-banner {
  width: 100%;
  height: 48vw;
}
.home {
  background: #f4f4f4;
  position: relative;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  .card-list {
    padding: 0 10px 10px 10px;
    overflow-y: auto;
    min-height: calc(100vh - 90px);
    -webkit-overflow-scrolling: touch;
  }

  .list-filter-wrap {
    height: 50px;
  }
  .list-filter-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
  }
  .list-filter {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
  }
}
</style>