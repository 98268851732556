<template>
  <div class="tips-container">
    <div class="tips-left van-ellipsis">
      <img class="icon-tips" src="@/assets/icon-tips.png" alt="" />
      <span class="tips-text van-ellipsis">{{ tips }}</span>
    </div>
    <span class="tips-right" @click="show = true">
      查看详情
      <img class="icon-right" src="@/assets/icon-right-primary.png" alt="" />
    </span>

    <van-popup
      class="tips-popup"
      v-model="show"
      get-container="body"
      round
      closeable
      position="bottom"
    >
      <div class="popup-header">提示详情</div>
      <div class="popup-tips-text">{{ tips }}</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    tips: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped>
.tips-container {
  display: flex;
  align-items: center;
  margin: 10px 10px 0;
  padding: 0 10px;
  height: 40px;
  background-color: #fff;
  border-radius: 10px;
}

.tips-left {
  display: flex;
  align-items: center;
  flex: 1;
}

.tips-text {
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #475266;
}

.popup-tips-text {
  font-size: 14px;
  line-height: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #475266;
  max-height: 80vh;
  white-space: pre-wrap;
}
.icon-tips {
  margin-right: 5px;
  width: 12px;
  height: 12px;
}

.tips-right {
  width: 100px;
  text-align: right;
  color: #00c4b3;
  font-size: 13px;
}

.icon-right {
  margin-left: 2px;
  width: 8px;
  height: 8px;
}

.tips-popup {
  padding: 0 20px 40px 20px;
}

.popup-header {
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
}
</style>