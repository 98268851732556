<template>
  <div class="filter-buttons">
    <span class="button-item" @click="onSortComprehensive">
      综合
      <img src="@/assets/icon-sort-down.png" class="icon-sort-down" />
    </span>
    <van-dropdown-menu class="button-item" active-color="#00c4b3">
      <van-dropdown-item
        :title="categoryTitle"
        v-model="categoryValue"
        :options="categoryOption"
        @change="onChangeCategory"
        @open="onOpenFilter"
      />
      <img src="@/assets/icon-sort-down.png" class="icon-sort-down" />
    </van-dropdown-menu>
    <span class="button-item" @click="onSortPrice">
      价格
      <div class="button-sort">
        <img :src="priceIcon" class="icon-sort" />
      </div>
    </span>

    <span class="button-item" @click="onSortSales">
      销量
      <div class="button-sort">
        <img :src="salesIcon" class="icon-sort" />
      </div>
    </span>
    <van-dropdown-menu class="button-item" active-color="#00c4b3">
      <van-dropdown-item
        :title="sexTitle"
        v-model="sexValue"
        :options="sexOption"
        @change="onChangeSex"
        @open="onOpenFilter"
      />
      <img src="@/assets/icon-sort-down.png" class="icon-sort-down" />
    </van-dropdown-menu>
  </div>
</template>

<script>
import iconUp from "@/assets/icon_sort_up.svg";
import iconDown from "@/assets/icon_sort_down.svg";
import iconDefault from "@/assets/icon_sort_default.svg";
export default {
  name: "filterButtons",
  props: {
    categoryList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      iconUp,
      iconDown,
      iconDefault,
      sortFilter: "",
      priceAsc: 0,
      salesAsc: 0,
      sexValue: "",
      categoryValue: "",
      sexOption: [
        { text: "全部", value: "" },
        { text: "男女通用", value: 0 },
        { text: "仅限男", value: 1 },
        { text: "仅限女", value: 2 },
      ],
      sexTitle: "性别",
      categoryOption: [],
      categoryTitle: "体检类型",
    };
  },
  computed: {
    priceIcon() {
      if (this.priceAsc === 1) {
        return this.iconUp;
      } else if (this.priceAsc === -1) {
        return this.iconDown;
      } else {
        return this.iconDefault;
      }
    },
    salesIcon() {
      if (this.salesAsc === 1) {
        return this.iconUp;
      } else if (this.salesAsc === -1) {
        return this.iconDown;
      } else {
        return this.iconDefault;
      }
    },
    asc() {
      if (this.sortFilter === "price") {
        return this.priceAsc;
      } else if (this.sortFilter === "sales") {
        return this.salesAsc;
      } else {
        return "";
      }
    },
  },
  watch: {
    categoryList(value) {
      if (value && value.length) {
        this.categoryOption = value;
      }
    },
  },
  methods: {
    onChangeSex(value) {
      this.sexTitle = this.sexOption.find((item) => item.value === value).text;
      this.emitChange();
    },
    onChangeCategory(value) {
      this.categoryTitle = this.categoryOption.find(
        (item) => item.value === value
      ).text;
      this.emitChange();
    },
    onSortPrice() {
      this.sortFilter = "price";
      this.salesAsc = 0;
      let priceAsc = this.priceAsc;
      if (priceAsc === 0) {
        this.priceAsc = 1;
      } else if (priceAsc === 1) {
        this.priceAsc = -1;
      } else {
        this.priceAsc = 0;
      }
      this.emitChange();
      this.onOpenFilter();
    },
    onSortSales() {
      this.sortFilter = "sales";
      this.priceAsc = 0;
      let salesAsc = this.salesAsc;
      if (salesAsc === 0) {
        this.salesAsc = -1;
      } else if (salesAsc === 1) {
        this.salesAsc = -1;
      } else {
        this.salesAsc = 0;
      }
      this.emitChange();
      this.onOpenFilter();
    },
    onSortComprehensive(){
      this.sortFilter = "combined";
      this.priceAsc = 0;
      this.salesAsc = 0

      this.emitChange();
      this.onOpenFilter();
    },
    onOpenFilter() {
      this.$emit("onOpenFilter");
    },
    emitChange() {
      this.$emit("change", {
        sex: this.sexValue,
        productPersonCategoryKey: this.categoryValue,
        sortFilter: this.sortFilter,
        asc: this.asc,
      });
    },
  },
};
</script>

<style lang="scss">
.filter-buttons {
  display: flex;
  justify-content: space-evenly;

  height: 100%;
  width: 100%;
  .van-dropdown-menu__bar {
    box-shadow: none !important;
    background-color: transparent !important;
    .van-dropdown-menu__title {
      padding: 0;
      div {
        font-weight: bold;
        font-size: 14px;
      }
      ::after {
        // position: absolute;
        // top: 50%;
        // right: -6px;
        // margin-top: -6px;
        // border: 4px solid;
        // border-color: transparent transparent #ccc #ccc;
        // -webkit-transform: rotate(-45deg);
        // transform: rotate(-45deg);
        // content: "";
        content: none;
      }
    }
    .van-dropdown-menu__title::after {
      content: none;
    }
  }
  .button-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    .button-sort {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3px 0 2px;
    }
  }
  .icon-sort {
    display: inline-block;
    margin-left: 4px;
    width: 8px;
    height: 12px;
  }
  .icon-sort-down {
    width: 8px;
    height: 6px;
    margin-left: 4px;
  }
}
</style>